/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './rating.scss'
import PageHeader from '../../layouts/page-header/PageHeader';
import DownloadButton from './button';
import { useDispatch } from 'react-redux';
import { getRatings } from '../../store/actions';
import { base } from '../../store/api';
import Loading from '../../component/Loading';

const Rating = () => {
  const { t } = useTranslation()
  const language = localStorage.getItem('i18nextLng')
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [currentPage, setCurrentPage] = useState(1);
  const [ratings, setRatings] = useState([]);

  useEffect(() => {
    const lang = language === 'tm' ? 'tk' : language
    setLoading(true)
    getRatings(dispatch, {}, lang, (err, res) => {
      if (err) console.log(err);
      setRatings(res?.results || [])
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, currentPage])

  const handleClick = (src) => {
    const link = document.createElement("a");
    link.href = src;
    link.download = "document.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <>
      <div className="site-content">
        <PageHeader
          colSize="col-md-10"
          title={t("rating")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' },
          ]}
        />
        <div className="event-ara page-template content-wrapper">
          <div className="container">
            <div className="row">
              {/* {loading ?
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: "center", margin: "50px 0" }}>
                  <Loading style={{ width: "10%" }} />
                </div>
                :
                <div className="col-sm-12">
                  <div className='grid-wrapper grid-lg-2 grid-md-1'>
                    {ratings.map((item) => (
                      <div key={item.id} className="single-item mb-30">
                        <div className='single-item-content relative'>
                          <div className='img-wrapper event_date'>
                            <img style={{ width: '100%', objectFit: 'contain', height: '100%', padding: '10px 0' }} alt='' src={item.logo ? base + item.logo : null} />
                          </div>
                          <Link to={item.src ? base + item.src : null} target="_blank" className="event_info">
                            <h3>
                              {item.title}
                            </h3>
                          </Link>
                        </div>
                        <div style={{ cursor: 'pointer' }} onClick={() => handleClick(item.src ? base + item.src : null)}>
                          <DownloadButton />
                        </div>

                      </div>
                    ))}
                  </div>
                </div>
              } */}

              <div className="col-sm-12 border p-5 text-center">
                <p className='fs-2 py-5 my-5'>
                  {t("technical_works")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Rating;